var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.date && _vm.branchId && _vm.lineItems.length > 0)?[_c('div',{staticClass:"buttonText ml-1 d-flex flex-row flex-grow-1",attrs:{"id":"result"}},[_c('div',{staticClass:"d-flex flex-row mt-3 mr-5 animate__animated animate__fadeIn"},[_c('v-alert',{attrs:{"type":"success","dense":""}},[_c('div',{staticClass:" ft font-weight-medium text-uppercase font-size-sm"},[_vm._v(" Total Lube Sales - "),_c('span',{staticClass:"ft font-weight-bold font-size-md"},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.sum(_vm.lineItems, "amount"), _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" )))])])])],1)]),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12","sm":"12"}},[[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmationAlert)}}},[_c('v-col',[_c('v-row',[_c('div',{staticClass:"d-flex flex-column flex-fill box-shadow-light pt-5 pb-4 animate__animated animate__fadeIn"},[_c('div',{staticClass:"d-flex flex-column flex-fill pa-2 ma-1 ",staticStyle:{"background-color":"rgb(244, 245, 247)","border-radius":"6px"}},[_c('v-expansion-panels',_vm._l((_vm.lineItems),function(row,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('span',{staticClass:"ft font-weight-bold"},[_vm._v(" Product Name : "),_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","label":""}},[_vm._v(_vm._s(row.productName))])],1)]),_c('v-expansion-panel-content',[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Product Name","error-messages":errors[0],"prepend-inner-icon":"category","readonly":""},model:{value:(row.productName),callback:function ($$v) {_vm.$set(row, "productName", $$v)},expression:"row.productName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium text-sm",attrs:{"outlined":"","prepend-inner-icon":"all_inclusive","label":"Enter Quantity Sold","error-messages":errors[0]},model:{value:(row.quantitySold),callback:function ($$v) {_vm.$set(row, "quantitySold", $$v)},expression:"row.quantitySold"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"outlined":"","prefix":_vm.currency && _vm.currency.length > 0
                                      ? _vm.currency[0].symbol
                                      : 'GHS',"readonly":"","label":"Total Amount Sold","error-messages":errors[0]},model:{value:(row.amount),callback:function ($$v) {_vm.$set(row, "amount", $$v)},expression:"row.amount"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mx-2 pa-3",staticStyle:{"background-color":"rgb(244, 245, 247)","border-radius":"6px"}},[(row.changes)?_c('v-card',{staticClass:"d-flex flex-row flex-grow-1 pa-3",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 "},[_c('h3',{staticClass:"ft font-weight-medium font-size-md"},[_vm._v(" Add Damages ")]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-3"},[_c('validationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-size-md",attrs:{"dense":"","error-messages":errors[0],"label":"Quantity","outlined":""},model:{value:(row.changes.damages.quantity),callback:function ($$v) {_vm.$set(row.changes.damages, "quantity", $$v)},expression:"row.changes.damages.quantity"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-2"},[_c('v-textarea',{staticClass:"font font-size-md text-capitalize",attrs:{"dense":"","label":"Comment","outlined":""},model:{value:(row.changes.damages.comment),callback:function ($$v) {_vm.$set(row.changes.damages, "comment", $$v)},expression:"row.changes.damages.comment"}})],1)]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-2"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 "},[_c('h3',{staticClass:"font font-weight-medium font-size-md"},[_vm._v(" Add Transfers ")]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-3"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font mr-2  font-size-md ",attrs:{"dense":"","error-messages":errors[0],"label":"Quantity In","outlined":""},model:{value:(
                                            row.changes.transfers.quantityIn
                                          ),callback:function ($$v) {_vm.$set(row.changes.transfers, "quantityIn", $$v)},expression:"\n                                            row.changes.transfers.quantityIn\n                                          "}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-size-md",attrs:{"dense":"","error-messages":errors[0],"label":"Quantity Out","outlined":""},model:{value:(
                                            row.changes.transfers.quantityOut
                                          ),callback:function ($$v) {_vm.$set(row.changes.transfers, "quantityOut", $$v)},expression:"\n                                            row.changes.transfers.quantityOut\n                                          "}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-2"},[_c('v-textarea',{staticClass:"font font-weight-md",attrs:{"dense":"","label":"Comment","outlined":""},model:{value:(
                                          row.changes.transfers.comment
                                        ),callback:function ($$v) {_vm.$set(row.changes.transfers, "comment", $$v)},expression:"\n                                          row.changes.transfers.comment\n                                        "}})],1)])])]):_vm._e()],1)])],1)}),1)],1)])]),_c('RecordLubeSegregations',{attrs:{"date":_vm.date,"branchId":_vm.branchId,"currency":_vm.currency,"subLoading":_vm.subLoading,"lubeSalesAmount":_vm.sum(_vm.lineItems, 'amount'),"lubeSaleSegregation":_vm.lubeSaleSegregation},on:{"addRevenueRow":_vm.addRevenueRow}})],1)],1)]}}],null,false,3629782637)})]],2)],1)]:[_c('EmptyPage',{attrs:{"image":require("@/assets/folder.svg"),"title":'No Pending Lube Sales',"imageWidth":'100px',"subTitle":'Proceed to end-of-day Sales'}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }