<template>
  <v-container>
    <template v-if="date && branchId && lineItems.length > 0">
      <div class="buttonText ml-1 d-flex flex-row flex-grow-1" id="result">
        <div
          class="d-flex flex-row mt-3 mr-5 animate__animated animate__fadeIn"
        >
          <v-alert type="success" dense>
            <div class=" ft font-weight-medium text-uppercase font-size-sm">
              Total Lube Sales -
              <span class="ft font-weight-bold font-size-md">{{
                sum(lineItems, "amount")
                  | currencyFormat(
                    currency && currency.length > 0 ? currency[0].code : "GHS"
                  )
              }}</span>
            </div>
          </v-alert>
        </div>
      </div>
      <v-row
        align="center"
        justify="center"
        style="margin-top: 5px; margin-left:1px; margin-right:10px"
      >
        <v-col cols="12" sm="12" style="padding: 0;">
          <template>
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form
                @submit.prevent="handleSubmit(confirmationAlert)"
                class="px-1"
              >
                <v-col>
                  <v-row>
                    <div
                      class="d-flex flex-column flex-fill box-shadow-light pt-5 pb-4 animate__animated animate__fadeIn"
                    >
                      <div
                        class="d-flex flex-column flex-fill pa-2 ma-1 "
                        style="background-color: rgb(244, 245, 247); border-radius: 6px;"
                      >
                        <v-expansion-panels>
                          <v-expansion-panel
                            v-for="(row, index) in lineItems"
                            :key="index"
                          >
                            <v-expansion-panel-header>
                              <span class="ft font-weight-bold">
                                Product Name :
                                <v-chip small label class="mr-2">{{
                                  row.productName
                                }}</v-chip>
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div class="d-flex flex-row flex-grow-1">
                                <ValidationProvider
                                  tag="div"
                                  class="d-flex flex-row flex-grow-1 ma-2"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <v-text-field
                                    outlined
                                    label="Product Name"
                                    v-model="row.productName"
                                    class=" ft font-weight-medium font-size-sm"
                                    :error-messages="errors[0]"
                                    prepend-inner-icon="category"
                                    readonly
                                  />
                                </ValidationProvider>
                                <ValidationProvider
                                  tag="div"
                                  rules="required|numeric"
                                  v-slot="{ errors }"
                                  class="d-flex flex-row flex-grow-1 ma-2"
                                >
                                  <v-text-field
                                    outlined
                                    v-model="row.quantitySold"
                                    prepend-inner-icon="all_inclusive"
                                    label="Enter Quantity Sold"
                                    :error-messages="errors[0]"
                                    class=" ft font-weight-medium text-sm"
                                  />
                                </ValidationProvider>
                                <ValidationProvider
                                  tag="div"
                                  rules="required"
                                  v-slot="{ errors }"
                                  class="d-flex flex-row flex-grow-1 ma-2"
                                >
                                  <v-text-field
                                    outlined
                                    v-model="row.amount"
                                    :prefix="
                                      currency && currency.length > 0
                                        ? currency[0].symbol
                                        : 'GHS'
                                    "
                                    readonly
                                    label="Total Amount Sold"
                                    :error-messages="errors[0]"
                                    class="ft font-weight-medium text-sm"
                                  />
                                </ValidationProvider>
                              </div>
                              <div
                                class="d-flex flex-row flex-grow-1 mx-2 pa-3"
                                style="background-color: rgb(244, 245, 247); border-radius: 6px;"
                              >
                                <v-card
                                  v-if="row.changes"
                                  flat
                                  class="d-flex flex-row flex-grow-1 pa-3"
                                >
                                  <div class="d-flex flex-column flex-grow-1 ">
                                    <h3
                                      class="ft font-weight-medium font-size-md"
                                    >
                                      Add Damages
                                    </h3>
                                    <div
                                      class="d-flex flex-row flex-grow-1 mt-3"
                                    >
                                      <validationProvider
                                        v-slot="{ errors }"
                                        tag="div"
                                        class="d-flex flex-row flex-grow-1"
                                        rules="numeric"
                                      >
                                        <v-text-field
                                          dense
                                          :error-messages="errors[0]"
                                          class="font font-size-md"
                                          label="Quantity"
                                          v-model="row.changes.damages.quantity"
                                          outlined
                                        />
                                      </validationProvider>
                                    </div>
                                    <div
                                      class="d-flex flex-row flex-grow-1 mt-2"
                                    >
                                      <v-textarea
                                        dense
                                        label="Comment"
                                        v-model="row.changes.damages.comment"
                                        outlined
                                        class="font font-size-md text-capitalize"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex flex-column flex-grow-1 ml-2"
                                  >
                                    <div
                                      class="d-flex flex-column flex-grow-1 "
                                    >
                                      <h3
                                        class="font font-weight-medium font-size-md"
                                      >
                                        Add Transfers
                                      </h3>
                                      <div
                                        class="d-flex flex-row flex-grow-1 mt-3"
                                      >
                                        <ValidationProvider
                                          v-slot="{ errors }"
                                          tag="div"
                                          class="d-flex flex-row flex-grow-1"
                                          rules="numeric"
                                        >
                                          <v-text-field
                                            dense
                                            :error-messages="errors[0]"
                                            label="Quantity In"
                                            v-model="
                                              row.changes.transfers.quantityIn
                                            "
                                            class="font mr-2  font-size-md "
                                            outlined
                                          />
                                        </ValidationProvider>
                                        <ValidationProvider
                                          rules="numeric"
                                          tag="div"
                                          class="d-flex flex-row flex-grow-1"
                                          v-slot="{ errors }"
                                        >
                                          <v-text-field
                                            dense
                                            :error-messages="errors[0]"
                                            class="font font-size-md"
                                            label="Quantity Out"
                                            v-model="
                                              row.changes.transfers.quantityOut
                                            "
                                            outlined
                                          />
                                        </ValidationProvider>
                                      </div>
                                      <div
                                        class="d-flex flex-row flex-grow-1 mt-2"
                                      >
                                        <v-textarea
                                          dense
                                          label="Comment"
                                          class="font font-weight-md"
                                          v-model="
                                            row.changes.transfers.comment
                                          "
                                          outlined
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                    </div>
                  </v-row>
                  <RecordLubeSegregations
                    :date="date"
                    :branchId="branchId"
                    :currency="currency"
                    :subLoading="subLoading"
                    :lubeSalesAmount="sum(lineItems, 'amount')"
                    :lubeSaleSegregation="lubeSaleSegregation"
                    @addRevenueRow="addRevenueRow"
                  />
                </v-col>
              </v-form>
            </ValidationObserver>
          </template>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <EmptyPage
        :image="require(`@/assets/folder.svg`)"
        :title="'No Pending Lube Sales'"
        :imageWidth="'100px'"
        :subTitle="'Proceed to end-of-day Sales'"
      />
    </template>
  </v-container>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
import { currencyFormat } from "../../../../filters/currencyFormat";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "PendingLubeSales",
  components: {
    ValidationProvider,
    ValidationObserver,
    EmptyPage: () => import(`../../../../components/EmptyPage`),
    RecordLubeSegregations: () => import(`../RecordLubeSegregations`)
  },
  props: {
    date: {
      type: String,
      default: null
    },
    branchId: {
      type: String,
      default: null
    },
    currency: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      lineItems: [],
      lubeSaleSegregation: []
    };
  },
  filters: {
    currencyFormat
  },
  computed: {
    ...mapGetters({
      pendingLubeSales: "sales/getAllPendingLubeSales",
      rawLubesSegragationFromBackend: "sales/getAllLubeSaleSegregation",
      subLoading: "getIsSubLoading"
    })
  },
  watch: {
    rawLubesSegragationFromBackend(value) {
      this.lubeSaleSegregation = value.map(
        ({ customerId, customerBusinessId, amount, salesDate, comment }) => {
          return {
            customerName: customerId ? customerId?.id : customerBusinessId?.id,
            amount,
            comment,
            customerBusinessId,
            customerId,
            salesDate
          };
        }
      );
    },
    pendingLubeSales(payload) {
      this.lineItems = payload.map(lineItem => {
        return {
          productName: lineItem.productId?.name,
          productId: lineItem.productId?.id,
          amount: lineItem.amount,
          id: lineItem.id,
          quantitySold: lineItem.quantitySold,
          retailCost: lineItem?.productId?.lubeId[0].retailCost,
          priceToCustomers: lineItem?.productId?.lubeId[0].sellPriceToCustomers,
          changes: {
            transfers: {
              transfersId: lineItem?.transfers?.id ?? null,
              date: lineItem?.transfers?.date,
              quantityIn: lineItem?.transfers?.quantityIn ?? 0,
              quantityOut: lineItem?.transfers?.quantityOut ?? 0,
              comment: lineItem?.transfers?.comment ?? ""
            },
            damages: {
              damagesId: lineItem.damages ? lineItem.damages?.id : null,
              quantity: lineItem.damages ? lineItem.damages?.quantity : 0,
              comment: lineItem.damages ? lineItem.damages?.comment : ""
            }
          }
        };
      });
    },
    lineItems: {
      handler(items) {
        items.forEach(item => {
          item.amount = isNaN(parseInt(item.quantitySold))
            ? 0
            : parseInt(item.quantitySold) * item.priceToCustomers;
        });
      },
      deep: true
    }
  },
  methods: {
    addRevenueRow(payload) {
      this.lubeSaleSegregation.push(payload);
    },
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    confirmationAlert() {
      this.recordDailyLubeSales();
    },
    recordDailyLubeSales() {
      if (
        this.sum(this.lubeSaleSegregation, "amount") >
        this.sum(this.lineItems, "amount")
      ) {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Account doesn't seem to be tallied`,
            status: "red"
          },
          { root: true }
        );
      } else {
        let payload = {
          salesDate: this.date,
          lineItems: this.lineItems,
          creditSales: this.lubeSaleSegregation
            ?.map(
              ({
                customerBusinessId,
                customerId,
                customerName,
                amount,
                comment
              }) => {
                return {
                  amount,
                  comment,
                  customerId:
                    (!!customerBusinessId &&
                      !!customerBusinessId.businessRegistrationNumber) ||
                    (!!customerName?.businessRegistrationNumber &&
                      customerName?.directors)
                      ? customerBusinessId?.id ?? customerName.id
                      : customerId?.id ?? customerName?.id,
                  customerType:
                    (!!customerBusinessId?.businessRegistrationNumber &&
                      !!customerBusinessId?.directors) ||
                    (!!customerName?.businessRegistrationNumber &&
                      customerName?.directors)
                      ? "business"
                      : "individuals",
                  salesDate: this.date
                };
              }
            )
            .filter(sale => sale.amount !== 0)
        };
        this.$store.dispatch("sales/approvalOfDailyLubeSales", {
          body: payload,
          branchId: this.branchId
        });
      }
    }
  }
};
</script>
